@import '../../../styles/basics';

.error-overlay {
  // height is minus header + padding
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__error {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  &__heading {
    margin-bottom: 20px;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-image img {
      height: 160px;
      margin-bottom: 16px;
    }
    &-text {
      max-width: 600px;
    }
  }

  @include media-breakpoint-up(md) {
    &__error {
      justify-content: center;
      flex-direction: row;
    }

    &__heading {
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      margin: 0 20px 0 0;
      padding: 10px 23px 10px 0;
    }
    &__info {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
    &__info-image img {
      height: 300px;
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    // height is minus header + padding
    height: calc(100vh - 160px);
  }
}
